import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Registration from './components/Registration';
import Receipt from './components/Receipt';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Receipt />} />
                <Route path="/registrationpage" element={<Registration />} />
            </Routes>
        </Router>
    );
};

export default App;
