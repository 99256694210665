
// from date and todate and totals add in pdf view

import React, { useState, useEffect } from "react"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { toWords } from "number-to-words"
import jsPDF from "jspdf"
import "jspdf-autotable"
import "../App.css"
import generateReceipt from "./Receipt-template"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
const Receipt = () => {
  // Previous state declarations
  const [searchInput, setSearchInput] = useState("")
  const [userExists, setUserExists] = useState(false)
  const [formData, setFormData] = useState({
    receiptNo: "",
    mobileNo: "",
    name: "",
    date: "",
    amount: "",
    amountType: "",
    description: "",
    amountInWords: "",
  })
  const [receipt, setReceipt] = useState([])
  const [dateFilter, setDateFilter] = useState({
    startDate: "",
    endDate: "",
    mobileNumber: "",
  })
  // New state for filter type
  const [filterType, setFilterType] = useState("all")
  const [globalFilter, setGlobalFilter] = useState(false)
  // Add new state variables for page total and grand total
  const [pageTotal, setPageTotal] = useState(0)
  const [grandTotal, setGrandTotal] = useState(0)
  // Add new state variables for custom date range
  const [customDateRange, setCustomDateRange] = useState({
    fromDate: "",
    toDate: "",
  })

  // Previous useEffect and handlers remain the same
  useEffect(() => {
    const fetchLastReceiptNo = async () => {
      try {
        const response = await axios.get("https://node.ricehr.com/receiptdetails/last")
        if (response.data && response.data.lastReceiptNo !== undefined) {
          setFormData((prevData) => ({
            ...prevData,
            receiptNo: response.data.lastReceiptNo + 1,
          }))
        } else {
          setFormData((prevData) => ({
            ...prevData,
            receiptNo: 1,
          }))
        }
      } catch (error) {
        console.error("Error fetching last receipt number:", error)
        toast.error("Failed to fetch the last receipt number. Please check your backend.")
      }
    }

    fetchLastReceiptNo()
  }, [])

  // Previous handlers remain the same
  const handleSearch = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post("https://node.ricehr.com/checkmobile", { mobile: searchInput })
      if (response.data.exists) {
        setUserExists(true)
        setFormData((prevData) => ({
          ...prevData,
          mobileNo: searchInput,
          name: response.data.name,
        }))
        fetchReceiptData(searchInput)
        setDateFilter((prev) => ({ ...prev, mobileNumber: searchInput }))
      } else {
        setUserExists(false)
        toast.info("User not found, please register.")
      }
    } catch (error) {
      console.error("Error checking mobile number:", error)
      toast.error("Something went wrong. Please try again.")
    }
  }

  // Previous handlers remain the same
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))

    if (name === "amount") {
      setFormData((prevData) => ({
        ...prevData,
        amountInWords: toWords(Number(value)),
      }))
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post("https://node.ricehr.com/receiptdetails", formData)
      toast.success(response.data.message)
      fetchReceiptData(formData.mobileNo)

      setFormData((prevData) => ({
        ...prevData,
        receiptNo: prevData.receiptNo + 1,
        date: "",
        amount: "",
        amountType: "",
        description: "",
        amountInWords: "",
      }))
    } catch (error) {
      console.error("Error:", error)
      toast.error("Failed to save details. Please check your backend.")
    }
  }

  // Add new function for global filtering
  const fetchAllReceipts = async () => {
    try {
      const response = await axios.get("https://node.ricehr.com/receiptdetailsall")
      setReceipt(response.data)
    } catch (error) {
      console.error("Error fetching all receipts:", error)
      toast.error(
        error.response?.status === 404
          ? "The all receipts endpoint is not available. Please check your server configuration."
          : "Failed to fetch all receipts. Please try again.",
      )
      // Reset the global filter if the endpoint fails
      setGlobalFilter(false)
    }
  }

  const formatDate = (date) => {
    const d = new Date(date)
    const day = String(d.getDate()).padStart(2, "0") // Ensure two-digit day
    const month = String(d.getMonth() + 1).padStart(2, "0") // Months are 0-based
    const year = d.getFullYear()
    return `${day}-${month}-${year}` // Format: DD-MM-YYYY
  }

  const handleFilterTypeChange = async (e) => {
    const type = e.target.value
    setFilterType(type)

    if (type !== "custom") {
      try {
        let data = []

        if (globalFilter) {
          const response = await axios.get("https://node.ricehr.com/receiptdetailsall")
          data = response.data
        } else {
          if (!dateFilter.mobileNumber) {
            toast.error("Please select a mobile number first")
            return
          }
          const response = await axios.get(`https://node.ricehr.com/getreceipt/${dateFilter.mobileNumber}`)
          data = response.data
        }

        let filteredData = []
        const today = formatDate(new Date())

        switch (type) {
          case "today":
            filteredData = data.filter((item) => formatDate(item.Date) === today)
            break

          case "month":
            const currentMonth = new Date().getMonth()
            const currentYear = new Date().getFullYear()
            const startOfMonth = new Date(currentYear, currentMonth, 1)
            const endOfMonth = new Date(currentYear, currentMonth + 1, 0)

            setDateFilter((prev) => ({
              ...prev,
              startDate: formatDate(startOfMonth),
              endDate: formatDate(endOfMonth),
            }))

            filteredData = data.filter((item) => {
              const itemDate = new Date(item.Date)
              return itemDate >= startOfMonth && itemDate <= endOfMonth
            })
            break

          case "all":
          default:
            filteredData = data
        }

        setReceipt(filteredData)
      } catch (error) {
        console.error("Error applying filter:", error)
        toast.error("Failed to apply filter")
      }
    }
  }

  const handleDateFilterChange = (e) => {
    const { name, value } = e.target
    setDateFilter((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleFilterSubmit = async (e) => {
    e.preventDefault()
    if (!dateFilter.mobileNumber && !globalFilter) {
      toast.error("Please select a mobile number first")
      return
    }
    try {
      let response
      if (globalFilter) {
        response = await axios.get("https://node.ricehr.com/receiptdetailsall")
      } else {
        response = await axios.get(`https://node.ricehr.com/getreceipt/${dateFilter.mobileNumber}`)
      }
      let filteredData = response.data

      if (filterType === "today") {
        const today = new Date().toISOString().split("T")[0]
        filteredData = response.data.filter((item) => {
          const itemDate = new Date(item.Date).toISOString().split("T")[0]
          return itemDate === today
        })
      } else if (filterType === "month") {
        const currentDate = new Date()
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)

        filteredData = response.data.filter((item) => {
          const itemDate = new Date(item.Date)
          return itemDate >= firstDayOfMonth && itemDate <= lastDayOfMonth
        })
      } else if (filterType === "custom" && customDateRange.fromDate && customDateRange.toDate) {
        const startDate = new Date(customDateRange.fromDate)
        const endDate = new Date(customDateRange.toDate)
        endDate.setHours(23, 59, 59, 999) // Set to end of day

        filteredData = response.data.filter((item) => {
          const itemDate = new Date(item.Date)
          return itemDate >= startDate && itemDate <= endDate
        })

        setDateFilter((prev) => ({
          ...prev,
          startDate: formatDate(startDate),
          endDate: formatDate(endDate),
        }))
      }

      setReceipt(filteredData)
    } catch (error) {
      console.error("Error fetching filtered receipt data:", error)
      toast.error("Failed to fetch filtered data")
    }
  }

  const resetFilters = () => {
    setDateFilter({
      startDate: "",
      endDate: "",
      mobileNumber: dateFilter.mobileNumber,
    })
    setFilterType("all")
    setCustomDateRange({ fromDate: "", toDate: "" })
    if (globalFilter) {
      fetchAllReceipts()
    } else {
      fetchReceiptData(dateFilter.mobileNumber)
    }
  }

  // Previous handlers remain the same
  const generatePdf = async () => {
    const doc = new jsPDF()

    doc.setFontSize(16)
    doc.text("Receipt Details", 105, 20, null, null, "center")

    // Add date range information on the same line
    doc.setFontSize(12)
    const fromDate = dateFilter.startDate || customDateRange.fromDate || "N/A"
    const toDate = dateFilter.endDate || customDateRange.toDate || "N/A"
    doc.text(`From Date: ${fromDate}`, 14, 30)
    doc.text(`To Date: ${toDate}`, doc.internal.pageSize.width - 14, 30, { align: "right" })

    const headers = ["SNo", "ReceiptNo", "Name", "Amount"]
    const data = receipt.map((item, index) => [index + 1, item.ReceiptNo, item.Name, item.Amount])

    doc.autoTable({
      startY: 36, // Adjusted startY to accommodate the new date range layout
      head: [headers],
      body: data,
      theme: "grid",
    })

    // Add page total and grand total
    const finalY = doc.lastAutoTable.finalY || 36
    doc.setFontSize(12)
    // doc.text(`Page Total: ${pageTotal.toFixed(2)}`, 14, finalY + 10)
    doc.text(`Grand Total: ${grandTotal.toFixed(2)}`, doc.internal.pageSize.width - 14, finalY + 20, { align: "right" });


    const dateRange =
      dateFilter.startDate && dateFilter.endDate ? `-${dateFilter.startDate}-to-${dateFilter.endDate}` : ""

    doc.save(`receipt-details${dateRange}.pdf`)

    return doc.output("blob")
  }

  const handlePrintReceipt = async () => {
    try {
      await generateReceipt({
        receiptNo: formData.receiptNo,
        date: formData.date,
        name: formData.name,
        amount: formData.amount,
        amountInWords: formData.amountInWords,
        amountType: formData.amountType,
        description: formData.description,
      })
    } catch (error) {
      console.error("Error printing receipt:", error)
      toast.error("Failed to generate receipt")
    }
  }

  const fetchReceiptData = async (mobileNo) => {
    try {
      const response = await axios.get(`https://node.ricehr.com/getreceipt/${mobileNo}`)
      setReceipt(response.data)
    } catch (error) {
      console.error("Error fetching receipt data:", error.response?.data || error.message)
      toast.error("Failed to fetch receipt data. Please check your backend.")
    }
  }

  useEffect(() => {
    const total = receipt.reduce((sum, item) => sum + Number.parseFloat(item.Amount), 0)
    setPageTotal(total)
    setGrandTotal(total) // For now, page total and grand total are the same
  }, [receipt])

  return (
    <div className="container-fluid bg-light py-5">

      <div className="container">
        <h2 className="text-center mb-5">Receipt Management</h2>

        {/* Search Form */}
        <div className="card shadow-lg mb-4">
          <div className="card-body">
            <form onSubmit={handleSearch}>
              <div className="mb-3">
                <label htmlFor="mobileNumber" className="form-label">
                  Mobile Number
                </label>
                <div className="input-group">
                  <input
                    type="tel"
                    className="form-control"
                    id="mobileNumber"
                    placeholder="Enter mobile number"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    required={!globalFilter}
                    maxLength={10}
                  />
                  <button type="submit" className="btn btn-primary" disabled={globalFilter}>
                    Search
                  </button>
                </div>
                
              </div>
   
<Link to="/registrationpage" style={{ color: 'blue', textDecoration: 'none', fontWeight: '500' }}>
  <span style={{ marginRight: '10px', color: 'black' }}>If you are not a user, please</span> 
  <FontAwesomeIcon icon={faUser} /> Register
</Link>

            </form>
          </div>
        </div>

        {/* Receipt Form - Only show when mobile number is selected and not in global filter mode */}
        {userExists && !globalFilter && (
          <>
            {/* Receipt Form */}
            <div className="card shadow-lg mb-4">
              <div className="card-body">
                <div className="text-center mb-4">
                  <h3 className="font-bold text-2xl">Receipt No: {formData.receiptNo}</h3>
                </div>
                <form onSubmit={handleSubmit}>
                  {/* Previous form fields remain the same */}
                  <div className="row g-3">
                    <div className="col-md-6">
                      <label className="form-label">Mobile No.</label>
                      <input type="tel" className="form-control" name="mobileNo" value={formData.mobileNo} readOnly />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Name</label>
                      <input type="text" className="form-control" name="Name" value={formData.name} readOnly />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Date</label>
                      <input
                        type="date"
                        className="form-control"
                        name="date"
                        value={formData.date}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Type of Payment</label>
                      <select
                        className="form-select"
                        name="amountType"
                        value={formData.amountType}
                        onChange={handleChange}
                        required
                      >
                        <option value="">--Select--</option>
                        <option value="Google Pay">Google Pay</option>
                        <option value="Phone Pay">Phone Pay</option>
                        <option value="Cash Hand">Cash Hand</option>
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Amount / Value</label>
                      <input
                        type="number"
                        className="form-control"
                        name="amount"
                        value={formData.amount}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Amount in Words</label>
                      <input
                        type="text"
                        className="form-control"
                        name="amountInWords"
                        value={formData.amountInWords}
                        readOnly
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Description</label>
                      <textarea
                        rows={1}
                        
                        className="form-control"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center gap-3 mt-4">
                    <button type="submit" className="btn btn-primary btn-lg">
                      Submit
                    </button>
                    {/* <button type="button" className="btn btn-warning btn-lg" onClick={generatePdf}>
                      Print & Send PDF
                    </button> */}
                    <button type="button" className="btn btn-success btn-lg" onClick={handlePrintReceipt}>
                      Print Receipt
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
        <div className="card shadow-lg mb-4">
          <div className="card-body">
            <h4 className="card-title mb-4">Filter Receipts</h4>
            <form onSubmit={handleFilterSubmit}>
              <div className="row g-3">
                <div className="col-md-3">
                  <div className="form-check form-switch mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="globalFilterToggle"
                      checked={globalFilter}
                      onChange={(e) => {
                        setGlobalFilter(e.target.checked)
                        if (e.target.checked) {
                          fetchAllReceipts()
                        } else {
                          fetchReceiptData(dateFilter.mobileNumber)
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor="globalFilterToggle">
                      Show All Receipts
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <label className="form-label">Filter Type</label>
                  <select className="form-select" value={filterType} onChange={handleFilterTypeChange}>
                    <option value="all">Show All</option>
                    <option value="today">Today</option>
                    <option value="month">This Month</option>
                    <option value="custom">Custom Date Range</option>
                  </select>
                </div>
                {filterType === "custom" && (
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <label className="form-label">From Date</label>
                      <input
                        type="date"
                        className="form-control"
                        value={customDateRange.fromDate}
                        onChange={(e) => setCustomDateRange((prev) => ({ ...prev, fromDate: e.target.value }))}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">To Date</label>
                      <input
                        type="date"
                        className="form-control"
                        value={customDateRange.toDate}
                        onChange={(e) => setCustomDateRange((prev) => ({ ...prev, toDate: e.target.value }))}
                      />
                    </div>
                  </div>
                )}
                <div className="col-md-3 d-flex align-items-end">
                  <button type="submit" className="btn btn-primary me-2">
                    Apply Filter
                  </button>
                  <button type="button" className="btn btn-secondary" onClick={resetFilters}>
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* Receipt Table - Always visible */}
        <div className="card shadow-lg">
          <div className="card-body">
            <h4 className="card-title mb-4">{globalFilter ? "All Receipts" : "Receipt Details"}</h4>
            {(filterType === "month" || filterType === "custom") && dateFilter.startDate && dateFilter.endDate && (
              <div className="mb-3">
                <strong>Date Range: </strong>
                {`${dateFilter.startDate} to ${dateFilter.endDate}`}
              </div>
            )}
            <div className="table-responsive">
              <div className="d-flex justify-content-end">
              <button type="button" className="btn btn-warning btn-lg " style={{marginBottom:'10px'}} onClick={generatePdf}>
                        Report Pdf
                    </button>
              </div>
            
              <table className="table table-striped table-hover">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">Sno</th>
                    <th scope="col">ReceiptNo</th>
                    <th scope="col">Name</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {receipt.length > 0 ? (
                    receipt.map((receipt, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{receipt.ReceiptNo}</td>
                        <td>{receipt.Name}</td>
                        <td>{receipt.Amount}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        No records found for the selected criteria.
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  {/* <tr>
                    <td colSpan="3" className="text-end">
                      <strong>Page Total:</strong>
                    </td>
                    <td>
                      <strong>{pageTotal.toFixed(2)}</strong>
                    </td>
                  </tr> */}
                  <tr>
                    <td colSpan="3" className="text-end">
                      <strong>Grand Total:</strong>
                    </td>
                    <td>
                      <strong>{grandTotal.toFixed(2)}</strong>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default Receipt
