

import jsPDF from "jspdf";
import { format } from "date-fns";

const logoUrl = "/assets/images/banner.png";
const qrCodeBase64 = '/assets/images/qrcode.jpeg';

const generatePDF = (data) => {
  if (!data || !data.receiptNo) {
    console.error("Receipt data is incomplete.");
    alert("Error: Receipt data is missing.");
    return;
  }

 // Initialize jsPDF instance with landscape A5
const doc = new jsPDF({
  orientation: "landscape",
  unit: "mm",
  format: "a5",
})

try {
  // Add banner image across the top with adjusted dimensions
  try {
    doc.addImage(logoUrl, "PNG", 10, 10, 190, 30) // Reduced height from 40 to 30
  } catch (logoError) {
    console.error("Error loading banner:", logoError)
  }

  // Header text - moved up slightly
  doc.setFont("helvetica", "bold")
  doc.setFontSize(18)
  doc.setTextColor(220, 20, 20)
  doc.text("SATYAM SHIVAM SUNDARAM GAUNIVAS", doc.internal.pageSize.width / 2, 50, {
    // Changed from 60 to 50
    align: "center",
  })

  // Receipt details section - adjusted starting position
  const startX = 20
  let startY = 65 // Changed from 75 to 65
  const lineHeight = 8

  // Receipt styling
  doc.setFontSize(11)
  doc.setTextColor(0, 0, 0)
  doc.setFont("helvetica", "normal")

  // Receipt No (in red)
  doc.setTextColor(220, 20, 20)
  doc.text(`Receipt No: SSG ${data.receiptNo}`, startX, startY)
  doc.setTextColor(0, 0, 0)
  startY += lineHeight

  // Date (right aligned)
  const formattedDate = data.date ? format(new Date(data.date), "dd/MM/yyyy") : "Invalid Date"
  doc.text(`Date: ${formattedDate}`, doc.internal.pageSize.width - 30, startY, {
    align: "right",
  })

  // Main content - adjusted spacing
  startY += lineHeight * 1.5 // Reduced from 2 to 1.5
  doc.text(`Received with thanks from: ${data.name || "N/A"}`, startX, startY)
  startY += lineHeight

  doc.text(`Amount: ₹${data.amount || "0"}/-`, startX, startY)
  startY += lineHeight

  doc.text(`Rupees ${data.amountInWords || "Zero"} only`, startX, startY)
  startY += lineHeight

  doc.text(`Payment Mode: ${data.amountType || "N/A"}`, startX, startY)
  startY += lineHeight

  doc.text(`Purpose: ${data.description || "N/A"}`, startX, startY)

  // Add QR Code with adjusted position and size
  try {
    // Moved QR code up and made it slightly smaller
    // doc.addImage(qrCodeBase64, "PNG", 20, doc.internal.pageSize.height - 40, 20, 20) // Adjusted size and position
    doc.addImage(qrCodeBase64, "PNG", 150, 75, 40, 40);

  } catch (qrError) {
    console.error("Error loading QR code:", qrError)
  }

  // Add signature line at bottom right - adjusted position
  const signatureY = doc.internal.pageSize.height - 25 // Moved up slightly
  doc.line(doc.internal.pageSize.width - 60, signatureY, doc.internal.pageSize.width - 20, signatureY)
  doc.text("Authorized Signatory", doc.internal.pageSize.width - 40, signatureY + 5, { align: "center" })

  // Print handling
  doc.autoPrint()
  const pdfOutput = doc.output("bloburl")
  const printWindow = window.open(pdfOutput, "_blank")
  if (printWindow) {
    printWindow.print()
  } else {
    alert("Failed to open the print dialog.")
  }
} catch (error) {
  console.error("Error generating receipt:", error)
  alert("Failed to generate receipt. Please try again.")
}


};

export default generatePDF;

