
import React, { useEffect, useState } from "react"
import axios from "axios"
import "bootstrap/dist/css/bootstrap.min.css"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
const Registration = () => {
  const navigate = useNavigate(); // Initialize the navigation function
  const [members, setMembers] = useState([])
  const [newMember, setNewMember] = useState({
    name: "",
    phoneNo: "",
    dateOfBirth: "",
    anniversary: "",
    panCard: "",
    address: "",
    city: "",
    state: "",
    country: "",
  })

  // const fetchMembers = async () => {
  //   try {
  //     const response = await axios.get("https://node.ricehr.com/members")
  //     setMembers(response.data)
  //   } catch (error) {
  //     console.error("Error fetching members:", error)
  //     toast.error("Failed to fetch members. Please try again.", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //     })
  //   }
  // }

  const addMember = async (e) => {
    e.preventDefault()
    console.log("Form submitted", newMember) // Log the form data

    // Check if all required fields are filled
    const requiredFields = ["name", "phoneNo", "dateOfBirth", "panCard", "address", "city", "state", "country"]
    const isFormValid = requiredFields.every((field) => newMember[field].trim() !== "")

    if (!isFormValid) {
      toast.error("Please fill all required fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
      return
    }

    try {
      const response = await axios.post("https://node.ricehr.com/addmember", newMember, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      console.log("Server response:", response.data) // Log the server response
     
      setNewMember({
        name: "",
        phoneNo: "",
        dateOfBirth: "",
        anniversary: "",
        panCard: "",
        address: "",
        city: "",
        state: "",
        country: "",
      })
      // navigate('/');
      toast.success("Member added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
     
    } catch (error) {
      console.error("Error adding member:", error.response ? error.response.data : error.message)
      toast.error("Failed to add member. Please try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
  }

 

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setNewMember({ ...newMember, [name]: value })
  }

  return (
    <div className="container-fluid bg-light min-vh-100 py-5">
      <div className="container">
        <div className="card shadow-lg">
          <div className="card-header bg-primary text-white">
            <h1 className="mb-0">Member Registration</h1>
          </div>
          <div className="card-body">
            <form onSubmit={addMember} className="mb-4">
              <div className="row g-3">
                <div className="col-md-6">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    maxLength={25}
                    name="name"
                    value={newMember.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Phone Number</label>
                  <input
                    type="tel"
                    className="form-control"
                    maxLength={10}
                    name="phoneNo"
                    value={newMember.phoneNo}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Date of Birth</label>
                  <input
                    type="date"
                    className="form-control"
                    name="dateOfBirth"
                    value={newMember.dateOfBirth}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Anniversary</label>
                  <input
                    type="date"
                    className="form-control"
                    name="anniversary"
                    value={newMember.anniversary}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">PAN Card</label>
                  <input
                    type="text"
                    className="form-control"
                    name="panCard"
                    value={newMember.panCard}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    value={newMember.address}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-4">
                  <label className="form-label">City</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    value={newMember.city}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-4">
                  <label className="form-label">State</label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    value={newMember.state}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-4">
                  <label className="form-label">Country</label>
                  <input
                    type="text"
                    className="form-control"
                    name="country"
                    value={newMember.country}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="text-center mt-4">
                <button type="submit" className="btn btn-primary btn-lg">
                  Add Member
                </button>
              </div>
            </form>
   
<Link to="/" style={{ color: 'blue', textDecoration: 'none', fontWeight: '500', marginLeft: '900px' }}>
  <span style={{ marginRight: '10px', color: 'black' }}>Once registered, get your</span> 
  <FontAwesomeIcon icon={faFile} style={{ color: 'lightgreen', fontSize: '20px', marginRight: '5px' }} />
  Receipt
</Link>
          </div>
   
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Registration

